import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { DetailsContainer } from "./commonComponents";
import background from '../assets/about-background.jpg';

export const AboutContainer = styled((props) => (<DetailsContainer
  {...props}
/>))(({ theme }) => ({
  margin: '0',
  width: '100%',
  padding: '5% 5%',
  backgroundImage: `url(${background})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center center',

  position: 'relative',
  zIndex: '0',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    backgroundImage: 'linear-gradient(180deg, #000 1%, rgba(0, 0, 0, 0.9) 5%, transparent 75%, rgba(0, 0, 0, 0.93) 95%, #000)',
    zIndex: '-1',
  },
}));

export const AboutDetailSection = styled((props) => (<Box
  {...props}
/>))(({ theme }) => ({
  display: 'flex',
  zIndex: '2',
  justifyContent: 'center',

  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
    margin: '0px 5vw',
    alignItems: 'center',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    margin: '0px 5vw',
    alignItems: 'flex-start',
  },
}));

export const AboutDetails = styled((props) => (<Box
  {...props}
/>))(({ theme }) => ({
  display: 'initial',
  flex: '2',
  [theme.breakpoints.up('xs')]: {
    marginBottom: '3vh',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '0',
  },

  '& > p': {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
  },
}));

export const AboutImageSection = styled((props) => (<Box
  {...props}
/>))(({ theme }) => ({
  flex: '1',
  margin: '0',
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const AboutImage = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)',
  zIndex: '1',
  width: 'fit-content',
  lineHeight: '0',

  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '80%',
    '&::after': {
      top: '10px',
      left: '10px',
      zIndex: '-1',
      border: '2px solid #FFF',
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      width: '100%',
      lineHeight: '0',
      height: '100%',
      borderRadius: '2%',
      transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
    },

    '&:hover': {
      '&::after': {
        top: '-10px',
        left: '-10px',
        border: '2px solid #FFF',
      },
    },
  },

  '& > img': {
    width: '100%',
    borderRadius: '2%',
  },
}));

export const AboutDetailText = styled((props) => (<Typography
  {...props}
  variant='body1' component='p'
/>))(({ theme }) => ({
  marginBottom: '10px',
  color: theme.palette.primary.main,
  [theme.breakpoints.up('xs')]: {
    width: 'auto',
    fontSize: '0.8rem',
  },
  [theme.breakpoints.up('sm')]: {
    width: '80%',
    fontSize: '1rem',
  },
}));

export const AboutDetailSkillArea = styled((props) => (<Box
  component="ul"
  {...props}
/>))(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  alignItems: 'center',
  listStyle: 'none',
  padding: '0',
  margin: '0',
}));

export const AboutDetailSkill = styled((props) => (<Box
  component="li"
  {...props}
/>))(({ theme }) => ({
  marginBottom: '10px',
  borderRadius: '20px',
  marginRight: '10px',
  padding: '5px 10px',
  fontSize: '0.6rem',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: theme.palette.primary.main,
  border: `1px solid rgba(0, 0, 0, 0.5)`,
  transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)',

  ':hover': {
    backgroundColor: 'rgba(0, 0, 0, 1)',
    color: theme.palette.primary.main,
    transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)',
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '0.7rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.9rem',
  },
}));