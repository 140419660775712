import { motion } from "framer-motion";
import React, { useEffect } from "react";

const animation = {
  initial: { opacity: 0, y: 100 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -100 },
}

const animateFadeIn = {
  bottom: {
    initial: { opacity: 0, y: 100 },
    hidden: { opacity: 1, y: 0 },
  },
  right: {
    initial: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 100 },
  },
  left: {
    initial: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -100 },
  }
}

export const AnimatedPageFadeInFromRight = ({ children }) => {
  return (
    <motion.div
      variants={animateFadeIn.right}
      initial="hidden"
      whileInView="initial"
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
};

export const AnimatedPageFadeInFromLeft = ({ children }) => {
  return (
    <motion.div
      variants={animateFadeIn.left}
      initial="hidden"
      whileInView="initial"
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
};

export const AnimatedPageFadeInFromBot = ({ children }) => {
  return (
    <motion.div
      variants={animateFadeIn.bottom}
      initial="hidden"
      whileInView="initial"
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
};