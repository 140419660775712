import { createTheme } from '@mui/material';

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#FAFAFA',
      aboutBackground: 'rgba(35, 36, 42,1)',
    },
    secondary: {
      main: '#1976d2',
      selected: '#17cbeb',
      highlightedText: '#00ffe7',
    },
    background: {
      featured: 'rgba(243, 243, 243,1)',
      featured2: 'rgba(120, 120, 120,1)',
      about: 'rgba(19, 30, 55,1)',
      work: '#22272E',
      footer: 'rgba(35, 35, 35, 1)',
      input: 'rgba(43, 43, 43, 1)',
    },
    text: {
      black: "#000",
      reversePrimary: '#FFF',
      gray: '#FAFAFA',
      contrast: '#56ffef',
    },
  },
  sectionHeading: {
    padding: '5vh 5vw 3vh 5vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '3rem',
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0.8)',

    '&::before': {
      // Unicode Character “▸” with space unicode after
      content: '"\\25B6 "" "',
      fontSize: '1rem',
      margin: 'auto 5px auto 0px',
    },
  },
});