import React, { useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import { CustomTab, CustomTabs, Header, MiniHeader, ResponsiveBar, CustomDrawer, CustomDrawerList, DrawerHeading, CustomTabsDrawer } from '../../customComponents/customHeader';
import { HashLink } from 'react-router-hash-link';

import { useLocation, matchPath } from "react-router-dom";
import { Button, Tooltip, Typography } from '@mui/material';

// , ['/projects', 3]
const mainRoutes = [['/', 0]]

// , `${mainRoutes[1][0]}#top`
const premadeRoutes = [`${mainRoutes[0][0]}#top`, '/#featured', '/#about', '/#work'];

//   
const routes = [
  [premadeRoutes[0], <HomeIcon />, 'Home'],
  [premadeRoutes[1], <DesignServicesIcon />, 'Projects'],
  [premadeRoutes[2], <PersonIcon />, 'About'],
  [premadeRoutes[3], <SettingsIcon />, 'Work Experience'],
]

const useRouteMatch = (patterns) => {
  const { pathname, hash } = useLocation();
  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    let path = pathname + `${hash}`;
    const possibleMatch = matchPath(pattern, path);

    if (possibleMatch !== null)
      return premadeRoutes.findIndex(route => route === path);
  }
  return mainRoutes.find(route => route[0] === pathname)[1];
}

export const AppBar = () => {
  const routeMatch = useRouteMatch(premadeRoutes);

  return (
    <Header>
      <ResponsiveBar>
        <ResponsiveDrawer routeMatch={routeMatch} />
      </ResponsiveBar>
      <MiniHeader>
        <CustomTabs component="div" value={routeMatch}
          orientation="vertical">
          {routes.map((i, index) => (
            <Tooltip key={index} title={i[2]} placement="right" arrow >
              <CustomTab icon={i[1]} to={i[0]}
                scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                tabIndex={index}
                component={HashLink}>
              </CustomTab>
            </Tooltip>
          ))}
        </CustomTabs>
      </MiniHeader>
    </Header >

  )
}

const ResponsiveDrawer = (props) => {
  const [rightAnchor, setRightAnchor] = useState(false);

  const handleDrawerToggle = () => {
    setRightAnchor(!rightAnchor);
  };

  return (
    <>
      <Button onClick={() => { handleDrawerToggle(rightAnchor, true) }}>
        <MenuIcon fontSize='large' sx={{ display: 'block' }} />
      </Button>
      <CustomDrawer
        anchor="right"
        open={rightAnchor}
        onClose={handleDrawerToggle}
      >
        <CustomDrawerList>
          <DrawerHeading>
            <Typography variant="h5" component="h4" fontWeight={'bold'}>Symon Kurt<br /> San Jose</Typography>
            <br />
            <Typography variant="h7" component="p">Web Developer</Typography>
          </DrawerHeading>

          <CustomTabsDrawer component="div" value={props.routeMatch}
            orientation="vertical">
            {routes.map((i, index) => (
              <CustomTab key={index} label={i[2]} to={i[0]}
                scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                onClick={handleDrawerToggle}
                component={HashLink}>
              </CustomTab>
            ))}
          </CustomTabsDrawer>
        </CustomDrawerList>
      </CustomDrawer>
    </>
  )
}