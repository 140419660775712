import EPortfolio from './EPortfolio.png'
import SortingVisualizer from './SortingVisualizer.png'
import Unavailable from './Unavailable.png'
import API from './API.png'

export const projects = [
  {
    name: 'Personal Website',
    subtitle: 'My eportfolio website',
    technologies: ['React', 'Git', 'Material UI'],
    image: EPortfolio,
    links: {
      github: false,
      live: window.location.href,
    }
  },
  {
    name: 'NHSTA API',
    subtitle: 'API for the National Highway Traffic Safety Administration',
    technologies: ['Node', 'Express', 'Chai & Mocha', 'Heroku', 'TypeScript', 'Data Structures'],
    image: API,
    links: {
      github: 'https://github.com/ssanjose/nhtsa-api',
      live: 'https://nhtsa-api-assessment-312309210.herokuapp.com/',
    },
  },
  {
    name: 'Sorting Algorithm Visualizer',
    subtitle: 'A visualizer for sorting algorithms.',
    technologies: ['React', 'Vanilla CSS', 'Visualizing Techniques'],
    image: SortingVisualizer,
    links: {
      github: 'https://github.com/ssanjose/sortingalgovisualizertool',
      live: 'https://ssanjose.github.io/sortingalgovisualizertool/',
    },
  },
];