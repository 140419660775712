import { styled } from '@mui/system'
import { Box, Tabs, Tab, Drawer } from '@mui/material';

export const Header = styled('header')(({ theme }) => ({
  zIndex: '2',
  [theme.breakpoints.up('xs')]: {
    width: 'fit-content',
    height: 'fit-content',
    position: 'fixed',
    right: '3%',
    top: '3%'
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    top: 0,
    backgroundColor: '#1d2021',
    position: 'sticky',
  },
}));

export const ResponsiveBar = styled('div')(({ theme }) => ({
  color: 'white',
  backgroundColor: 'rgba(29, 32, 33,0.7)',
  borderRadius: '3px',

  '& > button': {
    minWidth: 'fit-content',
  },
  '& svg': {
    width: '3rem',
    height: '3rem',
  },

  [theme.breakpoints.up('xs')]: {
    display: 'block',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const MiniHeader = styled(Box)(({ theme }) => ({
  height: '100vh',

  alignItems: 'center',
  position: 'sticky',
  top: 0,
  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}));

export const CustomTabs = styled((props) => (<Tabs
  {...props}
  TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
/>))(({ theme }) => ({
  m: 'auto',
  '& .MuiTabs-indicator': {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.selected,
  },
}));

export const CustomTabsDrawer = styled((props) => (<Tabs
  {...props}
  TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
/>))(({ theme }) => ({
  margin: 'auto',
  '& .MuiTabs-indicator': {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    backgroundColor: '#1d2021',
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const CustomTab = styled(Tab)(({ theme }) => ({
  margin: 0,
  padding: '15px',
  width: 'fit-content',
  height: 'fit-content',
  minWidth: '40px',
  color: 'rgba(255, 255, 255, 0.7)',
  '&.Mui-selected': {
    color: theme.palette.secondary.selected,
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'red',
  },
}));

export const CustomDrawer = styled(Drawer)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  '& .MuiPaper-root': {
    overflow: 'hidden',
  },
}));

export const CustomDrawerList = styled((props) => (<Box
  {...props}
/>))(({ theme }) => ({
  width: '75vw',
  height: '100%',
  backgroundColor: '#1d2021',
}));

export const DrawerHeading = styled(Box)(({ theme }) => ({
  width: '100%',
  color: 'white',
  height: '20%',
  display: 'flex',
  padding: '10%',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
}));