import { styled } from '@mui/system'
import { Box, Button, Link, Typography } from '@mui/material';
import background from '../assets/hero-background.jpg';
import { motion } from 'framer-motion';

export const CustomHero = styled(Box)(({ theme }) => ({
  height: '100vh',
  background: 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8) 50%, rgba(20, 24, 35, 0.8) 80%, rgb(20, 24, 35))',
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
    padding: '0 5vw',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    padding: '0 10%',
  },
}));

export const HeroBackground = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${background})`,
  width: 'auto',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center center',
  position: 'relative',
}));

export const NameSection = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  width: 'fit-content',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  flex: '8',

  [theme.breakpoints.up('xs')]: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'block',
    padding: '0 8%',
  },
}));

export const SmallIntro = styled(motion(Typography))(({ theme }) => ({
  color: theme.palette.secondary.highlightedText,

  [theme.breakpoints.up('xs')]: {
    fontSize: '0.9rem',
    marginBottom: '20px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.2rem',
    marginBottom: '0',
  },
}));

export const Heading1 = styled(motion(Typography))(({ theme }) => ({
  fontWeight: 'bold',
  letterSpacing: '2px',
  fontSize: 'clamp(2.5rem, 8vw, 6.5rem)',
}));

export const SubHeading1 = styled(Heading1)(({ theme }) => ({
  fontSize: 'clamp(1.5rem, 6vw, 4rem)',
  color: theme.palette.grey[300],
}));

export const Heading2 = styled(motion(Typography))(({ theme }) => ({
  width: '60%',
  color: theme.palette.grey[500],
  fontSize: 'clamp(0.5rem, 3vw, 1.1rem)',
}));

export const HeroButton = styled(motion(Button))(({ theme }) => ({
  margin: 0,
  marginTop: '15px',
  marginRight: '15px',
  width: 'fit-content',

  [theme.breakpoints.up('xs')]: {
    fontSize: '0.7rem',
    padding: '5px 14px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.9rem',
    padding: '6px 16px',
  },
}));

export const HeroWebsites = styled((props) => (<Box
  {...props}
  component='ul'
/>))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'end',
  width: 'fit-content',
  padding: '0',
  margin: '20px 0px 0px 0px',

  '& li': {
    listStyle: 'none',
    marginRight: '20px',
  }
}));

export const WebsiteLinks = styled(motion(Link))(({ theme }) => ({
  height: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textDecoration: 'none',

  [theme.breakpoints.up('xs')]: {
    fontSize: '0.7rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.9rem',
  },
}));