import { Box, Tooltip } from "@mui/material";
import { CustomHero, HeroButton, Heading1, Heading2, HeroBackground, HeroWebsites, NameSection, SmallIntro, WebsiteLinks, SubHeading1 } from "../../customComponents/customMain";
import { About } from "./About";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { DetailsContainer, ScrollHintsAnimated } from "../../customComponents/commonComponents";
import { NotableProjects } from "./Projects";
import { HashLink } from "react-router-hash-link";
import { WorkExperience } from "./Work";
import resume from '../../assets/resume/San-Jose,Symon-Kurt-Resume.pdf';
import { useAnimation } from "framer-motion";
import { useEffect } from "react";

export const Main = () => {
  return (
    <Box component="main" sx={{ width: '100%', overflow: 'hidden' }}>
      <Hero />
      <DetailsContainer>
        <NotableProjects />
        <About />
        <WorkExperience />
      </DetailsContainer>
    </Box>
  )
}

const Hero = () => {
  const animationControls = useAnimation();

  useEffect(() => {
    animationControls.start("animate");
  }, [])

  return (
    <HeroBackground>
      <CustomHero>
        <NameSection container columns={8}>
          <SmallIntro variant="h6"
            component="h4"
            initial="initial"
            animate={animationControls}
            custom={1}
            variants={textAnimation}>
            Hi, I am
          </SmallIntro>
          <br />
          <Heading1 variant="h1"
            component="h1"
            initial="initial"
            animate={animationControls}
            custom={2}
            variants={textAnimation}>
            Kurt San Jose.
          </Heading1>
          <SubHeading1 variant="h2"
            component="p"
            initial="initial"
            animate={animationControls}
            custom={3}
            variants={textAnimation}>
            I make websites and APIs.
          </SubHeading1>
          <Heading2 variant="body1"
            component="p"
            initial="initial"
            animate={animationControls}
            custom={3}
            variants={textAnimation}>
            I'm a Software Engineer who specializes Backend Development using NodeJS, Django, and Ruby-on-Rails. However, I create Frontend apps too using React, and Java/Kotlin for mobile applications.
          </Heading2>
          <HeroButton component={HashLink}
            variant="contained"
            color="primary"
            to="/#featured" smooth
            initial="initial"
            animate={animationControls}
            custom={4}
            variants={textAnimation}>
            SEE FEATURED PROJECTS
          </HeroButton>
          <HeroButton component={HashLink}
            variant="contained"
            color="secondary"
            to={resume}
            target="_blank"
            initial="initial"
            animate={animationControls}
            custom={4}
            variants={textAnimation}>
            SEE MY RESUME
          </HeroButton>
          <HeroProfLinks animationControls={animationControls} />
        </NameSection>
      </CustomHero>
      <ScrollHintsAnimated />
    </HeroBackground>
  )
}

const HeroProfLinks = ({ animationControls }) => {
  return (
    <HeroWebsites>
      {links.map((link, index) => {
        return (
          <li key={index}>
            <WebsiteLinks href={link.url} target="_blank" variant="body2"
              initial="initial"
              animate={animationControls}
              custom={4}
              variants={textAnimation}>
              <Tooltip title={link.name} placement="left" arrow>
                {link.icon}
              </Tooltip>
              {link.name}
            </WebsiteLinks>
          </li>
        )
      })}
    </HeroWebsites>
  )
};

const links = [
  {
    name: 'GitHub',
    url: 'https://github.com/ssanjose',
    icon: <GitHubIcon fontSize='large' />
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/in/kurtsanjose/',
    icon: <LinkedInIcon fontSize='large' />
  }
]

const textAnimation = {
  initial: {
    opacity: 0,
    y: 10,
  },
  animate: (custom) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: custom * 0.4,
    },
  }),
}