import { AppBar } from "./header/Header";
import { Box, ScopedCssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { AnimatedRoutes } from "./Animation/AnimatedRoutes";
import { ThemeProvider } from "@mui/system";
import { lightTheme } from "../themes/light";
import { Footer } from "./footer/Footer";

function App() {
  return (
    <ScopedCssBaseline enableColorScheme>
      <ThemeProvider theme={lightTheme}>
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
          }}
        >
          <BrowserRouter>
            <AppBar />
            <AnimatedRoutes />
          </BrowserRouter>
        </Box>
      </ThemeProvider>
    </ScopedCssBaseline>

  );
}

export default App;
