import { Box, ListItemIcon } from "@mui/material"
import { Featured, FeaturedBodyText, FeaturedDescriptionSection, FeaturedImage, FeaturedNumber, FeaturedPictureSection, FeaturedSkill, FeaturedSkillArea, FeaturedTitle, LinkItem, LinkItemButton, LinkList, NotableProjectsBox, NotableProjectsContainer } from "../../customComponents/customProject"
import { projects } from "../../assets/projectsData/projects";
import { SectionHeading } from "../../customComponents/commonComponents";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { OutlinedGithubIcon } from "../../assets/icons/outline_github";

export const Projects = () => {
  return (
    <Box component="main" sx={{ minHeight: "150vh", width: '100%' }} id="top">
      <h1>Projects</h1>
    </Box>
  )
}

export const NotableProjects = () => {
  return (
    <NotableProjectsContainer id="featured">
      <div style={{ margin: 'auto', width: '85%' }}>
        <SectionHeading
          variant="h4"
          component="h2"
          className="left white">Featured Projects</SectionHeading>
        <hr style={{ width: '10%', marginLeft: '0' }} />
        <br />
        <br />
      </div>
      <NotableProjectsBox>
        {projects.map((project, index) => {
          return (
            <NotableProject project={project} key={index} idx={index} />
          )
        })}
      </NotableProjectsBox>
    </NotableProjectsContainer>
  )
}

const NotableProject = (props) => {
  return (
    <Featured className={props.idx % 2 === 0 ? 'left' : 'right'}>
      <FeaturedDescriptionSection
        className={props.idx % 2 === 0 ? 'left' : 'right'}
      >
        <FeaturedNumber variant="subtitle2" component="h5">
          {"Featured #" + (props.idx + 1)}
        </FeaturedNumber>
        <FeaturedTitle variant="title" component="h3">
          {props.project.name}
        </FeaturedTitle>
        <FeaturedBodyText variant='subtitle2' component='p'>
          {props.project.subtitle}
        </FeaturedBodyText>

        <FeaturedSkillArea>
          {props.project.technologies.map((skill, index) => {
            return (
              <FeaturedSkill key={index}>{skill}</FeaturedSkill>
            )
          })}
        </FeaturedSkillArea>
        <LinkList>
          <LinkItem>
            <LinkItemButton href={props.project.links.github ? props.project.links.github : "#"}
              color="inherit"
              target="_blank"
              component="a"
              disabled={props.project.links.github ? false : true}>
              GitHub
              <OutlinedGithubIcon color="primary" />
            </LinkItemButton>
          </LinkItem>
          <LinkItem>
            <LinkItemButton href={props.project.links.live ? props.project.links.live : "#"}
              color="inherit"
              target="_blank"
              component="a"
              disabled={props.project.links.live ? false : true}>
              Live Project
              <ListItemIcon>
                <ShareOutlinedIcon color="primary" size="medium" />
              </ListItemIcon>
            </LinkItemButton>
          </LinkItem>
        </LinkList>
      </FeaturedDescriptionSection>
      <FeaturedPictureSection>
        <FeaturedImage src={props.project.image} alt={props.project.name} />
      </FeaturedPictureSection>
    </Featured >
  )
}