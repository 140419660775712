import { DetailsContainer } from "./commonComponents";
import { styled } from "@mui/system";
import { Timeline, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent } from "@mui/lab";
import { Box, Typography } from "@mui/material";

export const WorkContainer = styled((props) => (<DetailsContainer
  {...props}
/>))(({ theme }) => ({
  margin: '0',
  width: '100%',
  padding: '5% 5%',
  backgroundColor: theme.palette.primary.main,
  position: 'relative',
}));

export const WorkTitle = styled((props) => (<Typography
  {...props}
/>))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '3vh',
}));

export const DutyList = styled((props) => (<ul
  {...props}
/>))(({ theme }) => ({
  color: theme.palette.text.reversePrimary,
  [theme.breakpoints.up('xs')]: {
    paddingInlineStart: '15px',
  },
  [theme.breakpoints.up('sm')]: {
    paddingInlineStart: '20px',
  },
}));

export const CustomTimeline = styled((props) => (<Timeline
  {...props}
/>))(({ theme }) => ({
  margin: '0',
  padding: '0',
}));

export const CustomTimelineItem = styled((props) => (<TimelineItem
  {...props}
/>))(({ theme }) => ({
  '&::before': {
    // no content
    content: 'none',
  },
  paddingTop: '10px',
}));

export const CustomTimelineDot = styled((props) => (<TimelineDot
  {...props}
/>))(({ theme }) => ({
  margin: '0',
  marginTop: '-3px',
  marginBottom: '11.5px',
}))

export const CustomTimelineOppositeContent = styled((props) => (<TimelineOppositeContent
  {...props}
/>))(({ theme }) => ({
  color: theme.palette.text.black,
  [theme.breakpoints.up('xs')]: {
    padding: '0px 5px',
    fontSize: '0.6rem',
  },
  [theme.breakpoints.up('sm')]: {
    padding: '0px 16px 6px 16px',
    fontSize: '1.2rem',
  },
}));

export const CustomTimelineContent = styled((props) => (<TimelineContent
  {...props}
/>))(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.up('xs')]: {
    flex: '4',
    padding: '0px 0px 0px 5px',
  },
  [theme.breakpoints.up('sm')]: {
    flex: '1',
    padding: '0px 16px',
  },
}));

export const ContentContainer = styled((props) => (<Box
  {...props}
/>))(({ theme }) => ({
  margin: '0px 5px',
  position: 'relative',
  marginBottom: '5vh',
  padding: '20px',
  boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.75)',
  borderRadius: '10px',
  backgroundColor: theme.palette.background.work,
  '::before': {
    content: '""',
    left: 0,
    top: 0,
    borderRadius: '10px',
    position: 'absolute',
    backgroundColor: 'rgba(65,132,228,0.1)',
    width: '100%',
    height: '100%',
    display: 'block',
  },
  '> *': {
    position: 'relative',
    zIndex: '1',
  },
}));
