import TerminalIcon from '@mui/icons-material/Terminal';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';

export const workExperience = [
  {
    duration: 'January 2021 - April 2021',
    title: 'Software Developer',
    company: 'ACE Project Space',
    location: 'Winnipeg Manitoba',
    duties: ['Work with a team of 4 members to develop a full stack application',
      'Develop and improve a React frontend for a start up',
      'Add new features to the backend API server using Django and AWS',
      'Communicate and develop the application with the client in an agile environment',
    ],
    icon: <TerminalIcon />,
  },
  {
    duration: 'April 2021 - August 2021',
    title: 'Virtual Reality Developer - Backend',
    company: 'ACE Project Space',
    location: 'Winnipeg Manitoba',
    duties: ['Assist the development of the frontend of the application using React, and AFrame',
      'Plan and develop the network layer of the application using NodeJS and Express',
      'Plan and develop the backend API service using NodeJS, and Express',
      'Develop the database schema and database models using MongoDB and Mongoose',
      'Create low poly models for the application using Blender',
    ],
    icon: <TerminalIcon />,
  },
  {
    duration: 'July 2023 - Present',
    title: 'XU - Transport CA2',
    company: 'Sky Café',
    location: 'Winnipeg Manitoba',
    duties: ['Track and facilitate daily logistics',
      "Build food and beverage items for catering operations",
      'Help and communicate with the team to critically think and solve problems',
      'Travel with clearance and cater aircraft vehicles',
    ],
    icon: <LocalAirportIcon />,
  },
]