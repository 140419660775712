import { styled } from "@mui/system";
import { Box, TextField, Typography } from '@mui/material';
import React from "react";

export const FooterContainer = styled((props, ref) => (<div
  {...props}
/>))(({ theme }) => ({
  height: 'fit-content',
  width: '100%',
  backgroundColor: theme.palette.background.footer,
  color: theme.palette.text.reverseprimary,
  display: 'flex',
  borderTop: '2px solid rgba(0,0,0,1)',

  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
    minHeight: '150vh',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    minHeight: '100vh',
  },
}));

export const FooterContactContainer = styled((props) => (<Box
  {...props}
/>))(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '0px 0px 0px 5%',

  [theme.breakpoints.up('xs')]: {
    flex: '2',
  },
  [theme.breakpoints.up('sm')]: {
    flex: '1',
  },
}));

export const FooterMapContainer = styled(React.forwardRef((props, ref) => (<Box
  {...props}
  ref={ref}
/>)))(({ theme }) => ({
  flex: '1',
  backgroundColor: theme.palette.background.featured,
  position: 'relative',
  zIndex: '0',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(170, 255, 247,0.3)',
    zIndex: '1',
  },
}));

export const MapDetails = styled((props) => (<Box
  {...props}
/>))(({ theme }) => ({
  position: 'absolute',
  top: '5%',
  left: '3%',
  width: '220px',
  minHeight: '10%',
  backgroundColor: 'rgba(0,0,0,0.8)',
  padding: '3%',
  zIndex: '2',
}));

export const ContactTitle = styled((props) => (<Typography
  {...props}
/>))(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.contrast,
  [theme.breakpoints.up('xs')]: {
    fontSize: '3.3rem',
    marginBottom: '20px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '5rem',
    marginBottom: '40px',
  },
}));

export const FormContainer = styled((props) => (<Box
  {...props}
/>))(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '5%',
  '& > div': {
    marginBottom: '5%',
  },
}));

export const FormText = styled((props) => (<TextField
  {...props}
/>))(({ theme }) => ({
  minWidth: '50%',
  width: 'fit-content',
  backgroundColor: theme.palette.background.input,

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.text.gray,
    },
  },

  input: {
    color: theme.palette.text.gray,
  },
  svg: {
    color: theme.palette.text.contrast,
  }
}));

export const ContactForm = styled((props) => (<Box
  {...props}
/>))(({ theme }) => ({

}));