import { ContactTitle, FooterContactContainer, FooterContainer, FooterMapContainer, FormContainer, FormText, MapDetails } from "../../customComponents/customFooter";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from "mapbox-gl";
import { useEffect, useRef } from "react";
import { InputAdornment, Typography } from "@mui/material";
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useTheme } from "@mui/system";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const contactDetails = [
  {
    title: 'kurtsanjose58@gmail.com',
    icon: <AlternateEmailOutlinedIcon />,
  },
  {
    title: 'GitHub',
    icon: <GitHubIcon />,
  },
  {
    title: 'LinkedIn',
    icon: <LinkedInIcon />,
  }
]

export const Footer = () => {
  const theme = useTheme();
  const mapContainerRef = useRef();
  const map = useRef();

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-97.15, 49.89],
      zoom: 11,
      interactive: false,
      "layers": [
        {
          "id": "water",
          "source": "mapbox-streets",
          "source-layer": "water",
          "type": "fill",
          "paint": {
            "fill-color": "#00ffff"
          }
        }
      ]
    });
  }, []);

  return (
    <FooterContainer>
      <FooterContactContainer>
        <ContactTitle variant="h2" component="h2">
          Contact Me
        </ContactTitle>
        <FormContainer component="form">
          {contactDetails.map((contact, index) => {
            return (
              <FormText key={index}
                defaultValue={contact.title}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {contact.icon}
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            )
          })}
        </FormContainer>
      </FooterContactContainer>
      <FooterMapContainer ref={mapContainerRef}>
        <MapDetails>
          <Typography component="p" variant="body1" color={theme.palette.text.reversePrimary}>
            Symon Kurt San Jose,
            Winnipeg, MB
          </Typography>
        </MapDetails>
      </FooterMapContainer>
    </FooterContainer>
  )
};