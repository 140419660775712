import profileImage from "../../assets/profile.jpg";
import { ScrollHintsAnimated, SectionHeading } from "../../customComponents/commonComponents";
import { AboutContainer, AboutDetails, AboutDetailSection, AboutDetailSkill, AboutDetailSkillArea, AboutDetailText, AboutImage, AboutImageSection } from "../../customComponents/customAbout";
import { AnimatedPageFadeInFromRight } from "../Animation/AnimatedPage";

const AboutMe = [
  `Hi! My name is Kurt and I like to create software with different technologies.
  I am a software developer with a passion for creating beautiful UI and stable backend services.`,
  `My interest in software development started when I was a teenager.
  I was always interested in computers and programming that I took game development in highschool.`,
  `Fast forward to the present, in which I have worked as a software developer on two projects in a year.`,
  `Recently, I've worked on projects that include:`,
]

const TechnologyUsed = [
  `React`,
  `Material UI`,
  `NodeJS`,
  `Express`,
  `MongoDB`,
  `PostgreSQL`,
  `TypeScript`,
  `AFrame`,
  `Git`,
]

export const About = () => {
  return (
    <AboutPage />
  )
}

const AboutPage = () => {
  return (
    <AboutContainer id="about">
      <AnimatedPageFadeInFromRight>
        <SectionHeading
          variant="h4"
          component="h2"
          className="right white"
          sx={{ padding: '0px 5vw' }}>A little bit of myself</SectionHeading>
        <hr style={{ width: '10%', color: 'gray', marginRight: '5vw' }} />
        <br />
        <br />
        <AboutDetailSection>
          <AboutDetails>
            <AboutDetailText>
              {AboutMe[0]}
            </AboutDetailText>
            <AboutDetailText>
              {AboutMe[1]}
            </AboutDetailText>
            <AboutDetailText>
              {AboutMe[2]}
            </AboutDetailText>
            <AboutDetailText>
              {AboutMe[3]}
            </AboutDetailText>
            <AboutDetailSkillArea>
              {TechnologyUsed.map((tech, index) => {
                return (
                  <AboutDetailSkill key={index}>
                    {tech}
                  </AboutDetailSkill>
                )
              })}
            </AboutDetailSkillArea>
          </AboutDetails>
          <AboutImageSection>
            <AboutImage>
              <img src={profileImage}
                alt="profile" />
            </AboutImage>
          </AboutImageSection>
        </AboutDetailSection>
      </AnimatedPageFadeInFromRight>
      <ScrollHintsAnimated />
    </AboutContainer>
  )
}