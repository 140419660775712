import { Box } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Footer } from "../footer/Footer";
import { Main } from "../pages/Main";
import { Projects } from "../pages/Projects";

export const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Box>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Main />} />
          {/* <Route path="/projects" element={<Projects />} /> */}
        </Routes>
        <Footer />
      </Box>
    </AnimatePresence>
  )
}