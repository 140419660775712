import { TimelineConnector, TimelineDot, TimelineSeparator } from "@mui/lab";
import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { ContentContainer, CustomTimeline, CustomTimelineContent, CustomTimelineDot, CustomTimelineItem, CustomTimelineOppositeContent, DutyList, WorkContainer, WorkTitle } from "../../customComponents/customWork";
import { workExperience } from "../../assets/work/experience";
import SearchIcon from '@mui/icons-material/Search';

export const WorkExperience = () => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <WorkContainer>
      <WorkTitle variant="h4" component="h2" id="work">
        Work Experience
      </WorkTitle>
      <CustomTimeline position={match ? "alternate" : "right"}>
        <CustomTimelineItem>
          <CustomTimelineOppositeContent />
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <CustomTimelineContent />
        </CustomTimelineItem>
        {workExperience.map((work, index) => (
          <CustomTimelineItem key={index}>
            <CustomTimelineOppositeContent variant="body2" color="textSecondary">
              {work.duration}
            </CustomTimelineOppositeContent>
            <TimelineSeparator>
              <CustomTimelineDot variant="outlined">
                {work.icon}
              </CustomTimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <CustomTimelineContent>
              <ContentContainer>
                <Typography variant="h6" component="h1" color={theme.palette.text.reversePrimary}>
                  {work.title}
                </Typography>
                <Typography variant="subtitle1" color={theme.palette.text.reversePrimary}>
                  {work.company}
                </Typography>
                <Typography variant="subtitle2" color={theme.palette.text.reversePrimary} sx={{ marginBottom: '5px' }}>
                  {work.location}
                </Typography>
                <DutyList sx={{ direction: ((!match || index % 2 !== 0) ? "initial" : "rtl") }}>
                  {work.duties.map((duty, index) => (
                    <Typography variant="body2" component="li" key={index}>{duty}</Typography>
                  ))}
                </DutyList>
              </ContentContainer>
            </CustomTimelineContent>
          </CustomTimelineItem>
        ))}
        <CustomTimelineItem>
          <CustomTimelineOppositeContent variant="body2" color="textSecondary">
            Present
          </CustomTimelineOppositeContent>
          <TimelineSeparator>
            <CustomTimelineDot variant="outlined">
              <SearchIcon />
            </CustomTimelineDot>
          </TimelineSeparator>
          <CustomTimelineContent>
            <Typography variant="h6" component="h1">
              Looking for new opportunities
            </Typography>
          </CustomTimelineContent>
        </CustomTimelineItem>
      </CustomTimeline>
    </WorkContainer >
  )
};