import { Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import { useEffect, useState } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";

export const HeadingIcon = styled((props) => (<Typography
  {...props}
/>))(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.8)',
  fontSize: '0.8rem',
}));

export const DetailsContainer = styled((props) => (<Box
  {...props}
/>))(({ theme }) => ({
  minHeight: '60vh',
  height: 'fit-content',
  width: '100%',
  // backgroundColor: 'rgba(0, 0, 0, 0.8)',
}));

export const SectionHeading = styled((props) => (<Typography
  {...props}
/>))(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',

  [theme.breakpoints.up('xs')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '3.5rem',
  },

  '&.left': {
    justifyContent: 'flex-start',

    '::before': {
      // Unicode Character “▸” with space unicode before
      content: '"\\25B6 "" "',
      fontSize: '1rem',
      margin: 'auto 5px auto 0px',
    },
  },

  '&.right': {
    justifyContent: 'flex-end',

    '::after': {
      // Unicode Character “▸” with space unicode after
      content: '" "" \\25C0"',
      fontSize: '1rem',
      margin: 'auto 0px auto 5px',
    },
  },

  '&.white': {
    color: theme.palette.primary.main,
  }
}));

const hintAnimation = {
  initial: { y: 30 },
  animate: { y: 0 },
  transition: {
    repeat: Infinity,
    repeatType: 'reverse',
    duration: 0.8,
    ease: 'easeInOut',
  },
}

const ScrollHint = styled((props) => (<Box
  {...props}
>
  <motion.span
    variants={hintAnimation}
    initial="initial"
    transition={hintAnimation.transition}
    animate="animate"
  >scroll down</motion.span>
  <ArrowDownwardOutlinedIcon />
</Box>))(({ theme }) => ({
  position: 'absolute',
  bottom: '0px',
  right: '20px',
  height: '25vh',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: 'fit-content',
  color: 'white',
  padding: '2vh 0px',
  transformDuration: '0.5s',
  transformStyle: 'preserve-3d',

  zIndex: '2',

  '& > *': {
    fontSize: '1rem',
  },

  '& > span': {
    width: 'fit-content',
    height: 'fit-content',
    writingMode: 'vertical-rl',
  },

  '&.left': {
    left: '3px',
    '& > span': {
      transform: 'rotate(180deg)',
    },
  },

  '&.right': {
    right: '3px',
  },
}));

export const ScrollHintsAnimated = () => {
  return (
    <>
      <ScrollHint className="left" />
      <ScrollHint className="right" />
    </>
  );
}